import React, { useEffect } from "react";
import "./blog.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import envionment from "../assets/banners/guide.jpg";
import dataCentre from "../assets/banners/dataCenter.jpg";
import virtual from "../assets/banners/migrate.jpg";
import cosetEffective from "../assets/banners/costEffective.jpg";
// import Application from "../accordion/accordion";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
function Blog() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  const navigate = useNavigate();
  const goToblog1 = () => {
    navigate("/resources/blog/blog1");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToblog2 = () => {
    navigate("/resources/blog/blog2");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToblog3 = () => {
    navigate("/resources/blog/blog3");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToblog4 = () => {
    navigate("/resources/blog/blog4");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Header></Header>

      <div className="blog-banneerImage ">
        {/* <h6 className="blog-style" > <mark>Blog</mark> </h6> */}
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-5">
            <div className="card-container">
              <div className="card-image">
                <img
                  src={envionment}
                  style={{ width: "100%", height: "230px" }}
                />
              </div>
              <div className="card-content">
                <p className="">
                  Optimizing IT <br></br> Infrastructure for <br></br> Business Agility:  <br></br>A CTO’s
                  Guide
                </p>
                <button
                  className="button-34 blog-buttonStyle"
                  onClick={() => goToblog1()}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3  mb-5">
            <div className="card-container">
              <div className="card-image">
                <img
                  src={dataCentre}
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="card-content">
                <p className=" ">
                  The Importance  of <br></br> Data Centre  Refresh <br></br> in Organizations in  <br></br>the
                  USA
                </p>
                <button
                  className="button-34 blog-buttonStyle"
                  onClick={() => goToblog2()}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3  mb-5">
            <div className="card-container">
              <div className="card-image">
                <img src={virtual} style={{ width: "100%", height: "200px" }} />
              </div>
              <div className="card-content">
                <p className="">
                  How to <br></br> Successfully  Migrate  <br></br> to a Virtualized <br></br> Environment
                </p>
                <button
                  className="button-34 blog-buttonStyle"
                  onClick={() => goToblog3()}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3  mb-5">
            <div className="card-container">
              <div className="card-image">
                <img
                  src={cosetEffective}
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="card-content">
                <p className="">
                  Cost-Effective Virtualization for SMBs: Transitioning to
                  RedHat and Proxmox Hypervisors
                </p>
                <button
                  className="button-34 blog-buttonStyle"
                  onClick={() => goToblog4()}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
export default Blog;
