import React, { useEffect } from "react";
import "./virtuozzo.css";
import Footer from "../footer/footer";
import Header from "../header/header";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

function Virtuozzo() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      <Header></Header>
      <div className="solutionVirtuozzo-banner"></div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 ">
            <h2 className="header-style">Empowering Your IT Infrastructure</h2>

            <p>
              We understand the unique challenges faced by modern enterprises in
              managing and optimizing their IT infrastructure. Virtuozzo's
              advanced solutions offer Robust, Scalable, and Efficient ways to
              address these challenges, and our professional services ensure
              seamless implementation and ongoing support.
            </p>
            <p>
              Enhancing Resource Utilization and Reducing Costs Many
              organizations face the challenge of underutilized resources and
              escalating IT costs. Virtuozzo's virtualization and
              containerization technologies optimize resource usage, allowing
              you to run multiple workloads on a single hardware platform. This
              significantly reduces costs and maximizes your IT investment.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="virtuozzodecription-image"></div>
          </div>
        </div>
      </div>
      <div className=" container mt-5">
        <h2 >
          {" "}
          {/* Ensuring your business benefits from */}
        </h2>
        <div className="row mt-5 mb-5">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            <div class="service-card">
              <h3>Enhancing Resource Utilization and Reducing Costs</h3>
              <p>
                Many organizations face the challenge of underutilized resources
                and escalating IT costs. Virtuozzo's virtualization and
                containerization technologies optimize resource usage, allowing
                you to run multiple workloads on a single hardware platform.
                This significantly reduces costs and maximizes your IT
                investment.
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            <div class="service-card">
              <h3>Simplifying Hybrid Cloud Management</h3>
              <p>
                Managing a hybrid cloud environment can be complex and
                resource-intensive. Ensuring seamless integration and efficient
                management across on-premises and cloud resources is crucial.
                Virtuozzo's hybrid cloud solutions provide a unified platform
                for managing both on-premises and cloud resources, simplifying
                operations and enhancing flexibility.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div class="service-card">
              <h3>Ensuring Scalability and Performance</h3>
              <p>
                As businesses grow, their IT infrastructure must scale
                accordingly. Traditional infrastructure often faces performance
                bottlenecks and scalability issues. Virtuozzo's scalable
                infrastructure solutions enable seamless scaling of resources
                without compromising performance. Whether you need to add more
                capacity or enhance performance, Virtuozzo provides the tools to
                meet your needs.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div class="service-card">
              <h3>Enhancing Data Security and Compliance</h3>
              <p>
                Ensuring data security and compliance with industry regulations
                is a critical concern for businesses. Traditional IT
                environments often lack robust security measures and compliance
                frameworks. Virtuozzo's solutions come with built-in security
                features and compliance tools, ensuring that your data is
                protected and your infrastructure meets regulatory requirements.
              </p>
            </div>
          </div>
          {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
            <div class="service-card">
              <h3>
                Commitment to Innovation

              </h3>
              <p>
                We stay at the forefront of technology advancements,
                continuously updating our skills and knowledge. This commitment
                to innovation ensures that we deliver cutting-edge solutions
                that keep your business competitive.
              </p>
            </div>
          </div> */}
          {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-4">
            <div class="service-card">
              <h3>
                Integration Capabilities

              </h3>
              <p>
                Facilitates seamless integration within VMware's Omnissa EUC
                ecosystem, optimizing user experience and operational
                efficiency.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h4>Expertise in Virtuozzo Technologies</h4>
            <p>
              Our team of certified professionals has extensive experience with
              Virtuozzo's suite of solutions. We bring deep technical knowledge
              and best practices to every project, ensuring optimal
              implementation and support.
            </p>
            <h4>Tailored Professional Services</h4>
            <p>
              We understand that every business has unique needs. Our
              professional services are customized to address your specific
              challenges and goals. From initial consultation to ongoing
              support, we deliver solutions that align with your business
              objectives.
            </p>
            <h4>Proven Track Record</h4>
            <p>
              With a history of successful Virtuozzo deployments across various
              industries, we have the expertise to deliver results. Our clients
              benefit from improved efficiency, reduced costs, and enhanced
              performance.
            </p>
          </div>
          <div className="col-6">
            <h4>Comprehensive Support</h4>
            <p>
              We provide end-to-end support, from planning and implementation to
              maintenance and optimization. Our dedicated support team is always
              available to assist you, ensuring your Virtuozzo environment
              operates smoothly and efficiently.
            </p>
            <h4>Commitment to Innovation</h4>
            <p>
              We stay at the forefront of technology advancements, continuously
              updating our skills and knowledge. This commitment to innovation
              ensures that we deliver cutting-edge solutions that keep your
              business competitive.
            </p>
          </div>
        </div>
      </div>
      <div div className="background-overlay">
        <div className=" container ">
          <div className="col-12 mt-5">
            <h3>
              Choose us as your trusted partner for Virtuozzo solutions and
              experience the benefits of a robust, scalable, and efficient IT
              infrastructure. Contact us today to learn more about how we can
              transform your IT environment.
            </h3>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
export default Virtuozzo;
