import React from "react";
import "./App.css";
import Home from "./home";
import About from "./about/about";
import Services from "./service/services";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Contact from "./contact/contact";
import RedHat from "./solution/redhat";
import VmWare from "./solution/vmware";
import Nutanix from "./solution/nutanix";
import Proxmox from "./solution/proxmox";
import Virtuozzo from "./solution/virtuozzo";
import Blog from "./blog/blog";
import Blog1 from "./blog/blog1";
import Blog2 from "./blog/blog2";
import Blog3 from "./blog/blog3";
import Blog4 from "./blog/blog4";
import CoreInfrastructure from "./service/coreInfrastructure";
import EnduserComputing from "./service/endUserComputing";
import OperationAndSupport from "./service/operationAndSupport";
import NetworkServices from "./service/networkServices";
import ServerStorage from "./service/serverStorage";
import VirtualizationServices from "./service/virtualizationServices";
import CloudServices from "./service/cloudServices";
import EndUserComputingServices from "./service/endUserComputingServices";
import MobileDeviceManagement from "./service/mobileDeviceManagement";
import CollaborationCommunication from "./service/collaborationCommunication";
import MonitoringManagement from "./service/monitoring&Management";
import ComplianceGovernance from "./service/complianceGovernance";
import FAQ from "./fAQ/faq";
import CaseStudies from "./caseStudies/caseStudies";
import Webinar from "./webinar/webinar";
import WhitePapers from "./whitePapers/whitePapers";
import EBook from "./eBook/eBook";
import Video from "./video/video";
import ReactGA from "react-ga";

const TRACKING_ID = "G-BRB86YB2LP"; 
ReactGA.initialize(TRACKING_ID);
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/solution/redhat" element={<RedHat />} />
      <Route path="/solution/proxmox" element={<Proxmox />} />
      <Route path="/solution/nutanix" element={<Nutanix />} />
      <Route path="/solution/vmware" element={<VmWare />} />
      <Route path="/solution/virtuozzo" element={<Virtuozzo />} />

      <Route
        path="/services/core-infrastructure"
        element={<CoreInfrastructure />}
      ></Route>
      <Route
        path="/services/end-user-computing"
        element={<EnduserComputing />}
      ></Route>
      <Route
        path="/services/operation-support"
        element={<OperationAndSupport />}
      ></Route>
      <Route
        path="/services/core-infrastructure/network-services"
        element={<NetworkServices />}
      ></Route>
      <Route
        path="/services/core-infrastructure/storage-services"
        element={<ServerStorage />}
      ></Route>
      <Route
        path="/services/core-infrastructure/virtualization-services"
        element={<VirtualizationServices />}
      ></Route>
      <Route
        path="/services/core-infrastructure/cloud-services"
        element={<CloudServices />}
      ></Route>
      <Route
        path="/services/end-user-computing/desktop-Virtualization"
        element={<EndUserComputingServices />}
      ></Route>
      <Route
        path="/services/end-user-computing/moblie-device-management"
        element={<MobileDeviceManagement />}
      ></Route>
      <Route
        path="/services/operation-support/monitoring-management"
        element={<MonitoringManagement />}
      ></Route>
      <Route
        path="/services/operation-support/collaboration-communition"
        element={<CollaborationCommunication />}
      ></Route>
      <Route
        path="/services/operation-support/compliance-governance"
        element={<ComplianceGovernance />}
      ></Route>
      <Route path="/resources/blogs" element={<Blog />}></Route>
      <Route path="/resources/blog/blog1" element={<Blog1 />}></Route>
      <Route path="/resources/blog/blog2" element={<Blog2 />}></Route>
      <Route path="/resources/blog/blog3" element={<Blog3 />}></Route>
      <Route path="/resources/blog/blog4" element={<Blog4 />}></Route>
      <Route path="/resources/faq" element={<FAQ />}></Route>
      <Route path="/case-studies" element={<CaseStudies />}></Route>
      <Route path="/resources/white-papers" element={<WhitePapers />}></Route>
      <Route path="/resources/Webinars" element={<Webinar />}></Route>
      <Route path="/resources/eBook" element={<EBook />}></Route>
      <Route path="/resources/video" element={<Video />}></Route>
    </Routes>
  );
}

export default App;
