import React from "react";
import "./accordion.css";

class Accordion extends React.Component {
  render() {
    const {
      title,
      expand,
      onClick,
      description,
      description2,
      description3,
      description4,
      description5,
    } = this.props;

    return (
      <div>
        <div className="card mt-4">
          <dt
            className={expand ? "title is-expanded" : "title"}
            onClick={onClick}
          >
            <div className="accordionIcon flex">
              {/* <img src={iconImg}></img> */}
            </div>
            <div className="px-2 accordion-text">{title}</div>
          </dt>
          <dd
            className={expand ? "content is-expanded" : "content"}
            onClick={onClick}
          >
            <p>
              {description}
              <br></br>
              {description2}
              <br></br>
              {description3}
              <br></br>
              {description4}
              <br></br>
              {description5}
            </p>
          </dd>
        </div>
      </div>
    );
  }
}

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      block1: false,
      block2: false,
      block3: false,
    };
  }

  toggle = (index) => () => {
    this.setState({ [`block${index}`]: !this.state[`block${index}`] });
  };

  toggleExpand =
    (expand = false) =>
    () => {
      this.setState({
        block1: expand,
        block2: expand,
        block3: expand,
      });
    };

  render() {
    const accordionList = [
      {
        title: "What types of infrastructure services do you provide?",
        description:
          " Our professional services ensure a seamless and efficient platform migration with minimal disruption to your operations. We leverage our expertise in VMware, Nutanix, Proxmox, RedHat, and Virtuozzo to provide tailored migration strategies, ensuring data integrity and optimized performance.",
      },
      {
        title:
          "What is the benefit of using your professional services for platform migration?",
        description:
          " We're proud to have a team of certified   experts with extensive experience in virtualization technologies. With our in-depth knowledge and expertise,we're well-equipped to handle any  -related challenge and provide tailored solutions to meet your organization's needs.",
      },
      {
        title: "How can your virtualization services help my business?",
        description:
          "Our virtualization services optimize your IT resources by consolidating workloads, enhancing scalability, and improving disaster recovery capabilities. We provide both server and desktop virtualization solutions to create a more efficient, flexible, and cost-effective IT environment.",
      },
      {
        title:
          "What is End User Computing (EUC) and how can it benefit my organization?",
        description:
          "End User Computing (EUC) involves creating a secure, efficient, and flexible environment for users to access applications and data. Our EUC solutions, including Virtual Desktop Infrastructure (VDI) and endpoint device management, enhance productivity, improve user experience, and ensure data security.",
      },
      {
        title:
          "Why should I consider Mobile Device Management (MDM) for my business?",
        description:
          "MDM solutions help you securely manage your mobile devices, ensuring compliance and protecting sensitive data. Our MDM services include device enrollment, configuration management, security enforcement, and application management, providing comprehensive control over your mobile assets.",
      },
      {
        title: "How do your network services improve my IT infrastructure? ",
        description:
          "Our network services encompass design, implementation, monitoring, and security assessments to create a robust, scalable, and secure network infrastructure. We ensure your network meets current demands and is prepared for future growth, enhancing overall operational efficiency.",
      },
      {
        title: " What storage solutions do you offer? ",
        description:
          "We provide a range of storage solutions, including SAN/NAS implementation and management, storage virtualization, and backup and disaster recovery services. Our solutions ensure data availability, scalability, and protection, tailored to your specific needs.",
      },
      {
        title: "Can you help with cloud migration and management? ",
        description:
          " Yes, our cloud services include public, private, and hybrid cloud design, cloud migration, optimization, cost management, and security assessments. We ensure a smooth transition to the cloud, optimizing your resources and maintaining security and compliance.",
      },
      {
        title:
          "What is the process for data migration, and how do you ensure data integrity?",
        description:
          "Our data migration process involves thorough planning, data assessment, migration execution, and post-migration validation. We use best practices and advanced tools to ensure data integrity and minimize downtime during the migration process.",
      },
      {
        title: "How do you support ongoing infrastructure management? ",
        description:
          "We provide continuous monitoring, performance optimization, capacity planning, and help desk support to ensure your IT infrastructure remains optimal and secure. Our proactive approach helps identify and address potential issues before they impact your operations.",
      },
      {
        title: "What makes your services stand out from other providers? ",
        description:
          "Our deep expertise in leading technologies like VMware, Nutanix, Proxmox, RedHat, and Virtuozzo, combined with our commitment to tailored solutions and exceptional customer support, sets us apart. We focus on understanding your unique needs and delivering solutions that drive business success.",
      },
    ];

    return (
      <div className="container">
        <dl className="accordion">
          {accordionList.map((item, index) => (
            <Accordion
              key={index}
              title={item.title}
              description={item.description}
              description2={item.description2}
              description3={item.description3}
              description4={item.description4}
              description5={item.description5}
              onClick={this.toggle(index + 1)}
              expand={this.state[`block${index + 1}`]}
            />
          ))}
        </dl>
      </div>
    );
  }
}

export default Application;
