import React from "react";
import "./ourBrand.css";
import { useNavigate } from "react-router-dom";
import promox from "../assets/proxmox-authorized-reseller-logo-color-300px.png";
import redHat from "../assets/Red-Hat-logo1.png";
import virtuozzo from "../assets/Virtuozzo logo.png";

function OurBrands() {
  const navigate = useNavigate();

  const goToRedhat = () => {
    navigate("/solution/redhat");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToProxmmox = () => {
    navigate("/solution/proxmox");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToVirtuozzo = () => {
    navigate("/solution/virtuozzo");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="container">
        <div className="row ">
          <h1 className="ourBrand-style  "> Our Partnerships </h1>
          <div className="col-sm-12 col-md-6 col-lg-4  mt-4">
              <img src={redHat} className="image-style1 " />
              <div style={{ padding: "4% 0%" }}>
                <button className="button-34 btn-group " onClick={() => goToRedhat()}>
                  More Information
                </button>
              </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4   mt-4">
              {" "}
              <img src={promox} className="image-style2 col-12" />
              <div style={{ padding: "3%" }}>
                <button
                  className="button-34 btn-group"
                  style={{ marginLeft: "0%" }}
                  onClick={() => goToProxmmox()}
                >
                  More Information
                </button>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4    mt-4">
              {" "}
              <img src={virtuozzo} className="image-style3 col-12" />
              <div style={{ padding: "14% 3%" }}>
                <button className="button-34 btn-group" onClick={() => goToVirtuozzo()}>
                  More Information
                </button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OurBrands;
